import { lazy } from "react";
import { lazyRetry } from "../utils/helpers/routes.helpers";
import { RouteType } from "../utils/types/types";

const HomePage = lazy(() => lazyRetry(() => import("../pages/Home")));
const TermsPage = lazy(() => lazyRetry(() => import("../pages/Terms")));
const PolicyPage = lazy(() => lazyRetry(() => import("../pages/Policy")));
const ContactPage = lazy(() => lazyRetry(() => import("../pages/ContactUs")));
const NewsPage = lazy(() => lazyRetry(() => import("../pages/News")));
const NewsDetailsPage = lazy(() =>
  lazyRetry(() => import("../pages/NewsDetails"))
);
const PricingPage = lazy(() => lazyRetry(() => import("../pages/Pricing")));
const FAQsPage = lazy(() => lazyRetry(() => import("../pages/FAQs")));
const CreatorPage = lazy(() => lazyRetry(() => import("../pages/Creator")));

const routes: RouteType[] = [
  {
    path: "/",
    key: "home",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/term-of-use",
    key: "terms",
    name: "Terms",
    component: TermsPage,
  },
  {
    path: "/privacy-policy",
    key: "policy",
    name: "Policy",
    component: PolicyPage,
  },
  {
    path: "/contact-us",
    key: "contact-us",
    name: "ContactUs",
    component: ContactPage,
  },
  {
    path: "/news",
    key: "news",
    name: "News",
    component: NewsPage,
  },
  {
    path: "/news",
    key: "news",
    name: "News",
    component: NewsPage,
  },
  {
    path: "/news/details",
    key: "newsDetails",
    name: "News Details",
    component: NewsDetailsPage,
  },
  {
    path: "/pricing",
    key: "pricing",
    name: "Pricing",
    component: PricingPage,
  },
  {
    path: "/faqs",
    key: "faqs",
    name: "FAQs",
    component: FAQsPage,
  },
  {
    path: "/creator",
    key: "creator",
    name: "Creator",
    component: CreatorPage,
  },
];

export default routes;
