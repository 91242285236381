import React from "react";
import Logo from "../../assets/images/logo_new.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faDiscord,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { Divider } from "antd";

const Footer = () => {
  return (
    <div className="py-4 xl:py-6">
      <div className="hidden xl:block">
        <div className="grid grid-cols-4">
          <Link to="/">
            <div className="flex items-center">
              <div className="w-5">
                <img src={Logo} alt="logo" className="max-h-8" />
              </div>
              <div className="ml-2 text-base font-bold">Stitchbox</div>
            </div>
          </Link>
          <div className="col-span-2 text-center">
            <Link to="/term-of-use" className="text-roboto text-sm">
              Term of use
            </Link>
            <Link to="/privacy-policy" className="text-roboto text-sm ml-12">
              Privacy Policy
            </Link>
          </div>
          <div className="text-right">
            <a href="https://www.tiktok.com/@stitchbox.app?_t=8ctFsP4QExl&_r=1">
              <FontAwesomeIcon icon={faTiktok} className="mr-4 sm:mr-8" />
            </a>
            <a href="https://www.facebook.com/stitchbox.gg">
              <FontAwesomeIcon icon={faFacebookF} className="mr-4 sm:mr-8" />
            </a>
            <a href="https://instagram.com/stitchbox.app?igshid=OGQ5ZDc2ODk2ZA==">
              <FontAwesomeIcon icon={faInstagram} className="mr-4 sm:mr-8" />
            </a>
            <a href="https://discord.gg/n4GQg2bz">
              <FontAwesomeIcon icon={faDiscord} className="" />
            </a>
          </div>
        </div>
        <Divider className="my-4" />
        <div className="text-sm text-[#52525B]">
          © Copyright 2022, All Rights Reserved
        </div>
      </div>
      <div className="block xl:hidden">
        <div className="flex justify-between items-center">
          <Link to="/">
            <div className="flex items-center">
              <div className="w-6">
                <img src={Logo} alt="logo" className="max-h-8" />
              </div>
              <div className="ml-2 text-base font-bold">Stitchbox</div>
            </div>
          </Link>
          <div className="text-right">
            <a href="https://www.tiktok.com/@stitchbox.app?_t=8ctFsP4QExl&_r=1">
              <FontAwesomeIcon icon={faTiktok} className="mr-4 sm:mr-8" />
            </a>
            <a href="https://www.facebook.com/stitchbox.gg">
              <FontAwesomeIcon icon={faFacebookF} className="mr-4 sm:mr-8" />
            </a>
            <a href="https://instagram.com/stitchbox.app?igshid=OGQ5ZDc2ODk2ZA==">
              <FontAwesomeIcon icon={faInstagram} className="mr-4 sm:mr-8" />
            </a>
            <a href="https://discord.gg/n4GQg2bz">
              <FontAwesomeIcon icon={faDiscord} className="" />
            </a>
          </div>
        </div>
        <div className="mt-6">
          <Link to="/term-of-use">Term of use</Link>
        </div>
        <div className="mt-4">
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
        <Divider className="my-6" />
        <div className="text-sm text-[#52525B]">
          © Copyright 2022, All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
