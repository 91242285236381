import React, { ReactNode } from "react";
import Header from "./Header";
import LeftCornerDots from "../../assets/images/left_dots.png";
import RightCornerDots from "../../assets/images/right_dots.png";
import Footer from "./Footer";

interface Props {
  children?: ReactNode;
}

const HomeLayout = ({ children }: Props) => {
  return (
    <React.Fragment>
      <div className="home--screen h-full xl:h-screen w-screen px-4 xl:px-10">
        <div className="w-full h-full flex flex-col max-w-[1400px] mx-auto">
          <div className="flex-1 flex flex-col w-full h-full">
            <Header />
            <div className="flex-1">{children}</div>
          </div>
          <div className="hidden xl:block">
            <Footer />
          </div>
        </div>
      </div>
      <div className="absolute left-0 top-0">
        <img src={LeftCornerDots} alt="left-corner-dots" />
      </div>
      <div className="absolute right-0 bottom-0">
        <img src={RightCornerDots} alt="right-corner-dots" />
      </div>
    </React.Fragment>
  );
};

export default HomeLayout;
