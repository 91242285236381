import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AndroidFilled, AppleFilled } from "@ant-design/icons";
import Logo from "../../assets/images/logo_new.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Button, Drawer, Menu } from "antd";
import type { MenuProps } from "antd";

const Header = () => {
  const navigate = useNavigate();
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const mobileMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: "FAQ",
    },
    {
      key: "2",
      label: "Contact us",
    },
    {
      key: "3",
      label: "Creator",
    },
    {
      key: "4",
      label: "Term of Use",
    },
    {
      key: "5",
      label: "Privacy Policy",
    },
  ];
  const handleMenuClick = ({ key }: { key: string }) => {
    switch (key) {
      case "1":
        navigate("/faqs");
        setOpenMobileMenu(false);
        break;
      case "2":
        navigate("/contact-us");
        setOpenMobileMenu(false);
        break;
      case "3":
        navigate("/creator");
        setOpenMobileMenu(false);
        break;
      case "4":
        navigate("/term-of-use");
        setOpenMobileMenu(false);
        break;
      case "5":
        navigate("/privacy-policy");
        setOpenMobileMenu(false);
        break;
      default:
        setOpenMobileMenu(false);
        break;
    }
  };
  return (
    <React.Fragment>
      <div className="hidden xl:grid grid-cols-6 items-center py-8">
        <div>
          <Link to="/">
            <img src={Logo} alt="logo" className="max-h-14" />
          </Link>
        </div>
        <div className="col-span-4 flex items-center justify-center">
          <Link to="/faqs" className="text-roboto text-base font-light">
            FAQ
          </Link>
          <Link
            to="/contact-us"
            className="text-roboto text-base ml-20 font-light"
          >
            CONTACT US
          </Link>
          <Link
            to="/creator"
            className="text-roboto text-base ml-20 font-light"
          >
            CREATOR
          </Link>
        </div>
        <div className="text-right">
          <a href="https://apps.apple.com/vn/app/stitchbox/id1660843872">
            <AndroidFilled rev={undefined} style={{ fontSize: "24px" }} />
          </a>
          <a href="https://apps.apple.com/vn/app/stitchbox/id1660843872">
            <AppleFilled
              rev={undefined}
              style={{
                fontSize: "24px",
                marginLeft: "24px",
              }}
            />
          </a>
        </div>
      </div>
      <div className="flex xl:hidden py-6 justify-between items-center">
        <div className="flex flex-1 justify-center items-center">
          <Link to="/">
            <div className="w-8">
              <img src={Logo} alt="logo" className="ml-6" />
            </div>
          </Link>
        </div>

        <Button
          type="text"
          icon={<FontAwesomeIcon icon={faBars} size="lg" />}
          onClick={() => setOpenMobileMenu(true)}
        ></Button>
      </div>
      <Drawer
        width="100%"
        open={openMobileMenu}
        onClose={() => setOpenMobileMenu(false)}
        title={<span>Menu</span>}
      >
        <Menu items={mobileMenuItems} onClick={handleMenuClick}></Menu>
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
