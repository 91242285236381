import { Route, Routes } from "react-router-dom";
import routes from "./page-routes";
import HomeLayout from "../components/v3Layouts/HomeLayout";
import Layout from "../components/v3Layouts";

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.key}
          path={route.path}
          element={
            route.component ? (
              route.key === "home" ? (
                <HomeLayout>
                  <route.component />
                </HomeLayout>
              ) : (
                <Layout>
                  <route.component />
                </Layout>
              )
            ) : null
          }
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
