import React from "react";
import "antd/dist/reset.css";
import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";

const App = () => {
  return (
    <BrowserRouter>
      <React.Suspense>
        <AppRoutes />
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
