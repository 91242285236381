import React, { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";

interface Props {
  children?: ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <div className="main--screen min-h-full xl:min-h-screen w-screen px-4 xl:px-10">
      <div className="w-full min-h-full xl:min-h-screen flex flex-col max-w-[1400px] mx-auto">
        <Header />
        <div className="flex-1">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
